import React from "react";
import MainComponent from "../Component/MainComponent";
import RollingAds from "../Component/HomeSection/RollingAds";
import Hero from "../Component/HomeSection/Hero";
import AnimationSection from "../Component/HomeSection/AnimationSection";

const Home = () => {
  return (
    <MainComponent>
      <Hero />
      <RollingAds />
      <AnimationSection />
    </MainComponent>
  );
};

export default Home;
