import React, { useState, useEffect } from "react";

const TypeWriting = ({ textArray, delay }) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentStringIndex, setCurrentStringIndex] = useState(0);
  const [completedTexts, setCompletedTexts] = useState([]);

  useEffect(() => {
    if (currentStringIndex < textArray.length) {
      if (currentIndex < textArray[currentStringIndex].length) {
        const timeout = setTimeout(() => {
          setCurrentText(
            (prevText) => prevText + textArray[currentStringIndex][currentIndex]
          );
          setCurrentIndex((prevIndex) => prevIndex + 1);
        }, delay);

        return () => clearTimeout(timeout);
      } else if (currentStringIndex < textArray.length - 1) {
        const timeout = setTimeout(() => {
          setCompletedTexts((prevTexts) => [...prevTexts, currentText]);
          setCurrentText("");
          setCurrentIndex(0);
          setCurrentStringIndex((prevIndex) => prevIndex + 1);
        }, delay);

        return () => clearTimeout(timeout);
      } else if (
        currentStringIndex === textArray.length - 1 &&
        currentText !== ""
      ) {
        setCompletedTexts((prevTexts) => [...prevTexts, currentText]);
        setCurrentText("");
      }
    }
  }, [currentIndex, currentStringIndex, delay, textArray, currentText]);

  return (
    <div>
      {completedTexts.map((text, index) => (
        <div key={index}>{text}</div>
      ))}
      <div>{currentText}</div>
    </div>
  );
};

export default TypeWriting;
