import React from "react";
import { Icon } from "@iconify/react";

const Footer = () => {
  return (
    <div className="flex flex-col w-full justify-center items-center py-[48px] gap-[16px] text-sm">
      <img
        src="/images/header-logo.png"
        className="h-[24px]"
        alt="header-logo"
      />
      <p>{`>_ © ${new Date().getFullYear()} Depins. All Rights Reserved.`}</p>
      <div className="flex items-center gap-[24px]">
        {/* <a href="http://www.google.com" target="_blank">
          <Icon icon="heroicons:document-solid" />
        </a> */}
        <a href="http://t.me/DePINsCTO" target="_blank">
          <Icon icon="mage:telegram" />
        </a>
        <a href="https://x.com/depinsapp" target="_blank">
          <Icon icon="prime:twitter" />
        </a>
      </div>
    </div>
  );
};

export default Footer;
