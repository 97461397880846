import React from "react";
import { Icon } from "@iconify/react";
import { motion as m } from "framer-motion";

const Modal = ({ children, toggleModal, className }) => {
  return (
    <m.div
      className="absolute flex flex-col w-full h-screen top-0 left-0 bg-opacity-50 bg-black items-center justify-center z-30"
      variants={{
        hidden: { opacity: 0 },
        visible: { opacity: 1 },
      }}
      initial="hidden"
      animate="visible"
      transition={{ duration: 0.1 }}
    >
      <m.div
        className={`flex flex-col w-fit h-fit border-2 border-[#4284FF] ${className}`}
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
        }}
        initial="hidden"
        animate="visible"
        transition={{ duration: 0.2 }}
      >
        <div className="flex h-[22px] items-center justify-end bg-[#4284FF] p-[4px]">
          <Icon
            icon="ic:baseline-close"
            className="cursor-pointer"
            onClick={toggleModal}
          />
        </div>
        <div className="p-[32px] bg-black">{children}</div>
      </m.div>
    </m.div>
  );
};

export default Modal;
