import React, { useEffect, useState } from "react";
import MainComponent from "../Component/MainComponent";
import iotexTokenABI from "../hooks/abi/iotex_token_abi";
import proof from "../hooks/abi/proof";
import Button from "../Component/Button";
import Modal from "../Component/Modal";
import { Icon } from "@iconify/react";
import { ethers } from "ethers";
import Reveal from "../Component/Animation/Reveal";
import { useAccount, useReadContracts, useWriteContract } from "wagmi";
import useShorten from "../hooks/shorten";
import iotex_claim_abi from "../hooks/abi/iotex_claim_abi";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebase-config";
import { useNavigate } from "react-router-dom";
import { RESULT } from "../Router/path";

const ClaimPage = () => {
  const [userAddressInfo, setUserAddressInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [iotexBalance, setIotexBalance] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [claimData, setClaimData] = useState(null);
  const [showAnimation, setShowAnimation] = useState(false);
  const {
    writeContract: claimTokens,
    status: isSuccess,
    error: claimError,
    data: claimDataResponse,
  } = useWriteContract();

  const navigate = useNavigate();
  const shorten = useShorten();

  const { address: walletAddress, isConnected } = useAccount();
  const { data: accountBalance, isLoading: accountBalanceLoading } =
    useReadContracts({
      contracts: [
        {
          abi: iotexTokenABI,
          functionName: "balanceOf",
          address: process.env.REACT_APP_IOTEX_TOKEN_CONTRACT_ADDRESS,
          args: [walletAddress],
        },
      ],
    });
  const { data: totalTokens, isLoading: totalTokensLoading } = useReadContracts(
    {
      contracts: [
        {
          abi: iotexTokenABI,
          functionName: "totalSupply",
          address: process.env.REACT_APP_IOTEX_TOKEN_CONTRACT_ADDRESS,
          args: [],
        },
      ],
    }
  );

  const handleClaim = async () => {
    setLoading(true);
    toggleConfirmModal();
    if (claimData && claimData.amount && claimData.proof) {
      claimTokens({
        abi: iotex_claim_abi,
        address: process.env.REACT_APP_IOTEX_CLAIM_CONTRACT_ADDRESS,
        functionName: "claim",
        args: [walletAddress, claimData?.amount, claimData?.proof || []],
      });

      let amount = 0;
      amount = Number(ethers.formatEther(claimData.amount));

      if (isSuccess == "success") {
        console.log("Claimed successfully");
        setLoading(false);
        if (userAddressInfo && userAddressInfo.id) {
          await updateDoc(doc(db, "wallet_address", userAddressInfo.id), {
            amount: amount,
            proof: claimData.proof,
          });
        } else {
          await setDoc(doc(db, "wallet_address", walletAddress), {
            amount: amount,
            proof: claimData.proof,
          });
        }
        setShowAnimation(true);
      } else {
        setLoading(false);
      }
    } else {
      console.error("Claim data is not available or incomplete.");
    }
  };

  const toggleConfirmModal = () => {
    setShowConfirmModal(!showConfirmModal);
  };

  useEffect(() => {
    const getUsers = async () => {
      if (!walletAddress) return;

      const docRef = doc(db, "wallet_address", walletAddress);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setUserAddressInfo({ id: docSnap.id, ...docSnap.data() });
      }
    };

    getUsers();
  }, [walletAddress]);

  useEffect(() => {
    if (walletAddress && isConnected) {
      if (walletAddress in proof.claims) {
        setClaimData(proof.claims[walletAddress]);
        if (accountBalance && accountBalance.length > 0) {
          setIotexBalance(Number(ethers.formatEther(accountBalance[0].result)));
        }
      }
    }
  }, [walletAddress, isConnected, accountBalance]);

  const claimableText = [
    '>_ Initializing "Claim Page" sequence...\n',
    ">_ ",
    <span className="text-[#4284FF]">STATUS:</span>,
    " Feature ",
    <span className="text-[#22C55E]">READY</span>,
    ".\n",
    ">_ ",
    <span className="text-[#4284FF]">SYSTEM MESSAGE:</span>,
    ' "DePINs owners can now claim their tokens."\n\n',
    ">_ Verifying wallet status...\n",
    ">_ AUTHENTICATION: Awaiting wallet connection...",
    "\n>_ ",
    <span className="text-[#4284FF]">Wallet Connected: </span>,
    <span>{shorten(walletAddress)}</span>,
    "\n>_ ",
    <span className="text-[#4284FF]">Network Status:</span>,
    " IoTeX Mainnet\n\n",
    ">_ Fetching User DePINs Data...\n",
    "   /.. You own ",
    <span className="text-[#4284FF]">
      {claimData?.proof.length.toLocaleString() ?? 0}
    </span>,
    " DePINs.\n",
    "   /.. ",
    <span className="text-[#4284FF]">Claimed Tokens: </span>,
    <span>
      {userAddressInfo ? userAddressInfo.amount.toLocaleString() : 0}
    </span>,
    " $DePINs\n",
    "   /.. ",
    <span className="text-[#4284FF]">Claimable Tokens:</span>,
    <span
      className={
        claimData
          ? Number(ethers.formatEther(claimData?.amount)) -
              userAddressInfo?.amount !=
            0
            ? "text-[#22C55E]"
            : "text-red-500"
          : "text-red-500"
      }
    >
      {" "}
      {claimData
        ? userAddressInfo
          ? (
              Number(ethers.formatEther(claimData?.amount)) -
              userAddressInfo.amount
            ).toLocaleString()
          : Number(ethers.formatEther(claimData?.amount)).toLocaleString()
        : 0}{" "}
      $DePINs
    </span>,
    "\n\n>_ Retrieving Distribution Details & Status...\n",
    "   /.. ",
    <span className="text-[#4284FF]">Total Tokens Available for Claim:</span>,
    <span>
      {" "}
      {totalTokens &&
        totalTokens.length > 0 &&
        Number(ethers.formatEther(totalTokens[0].result).toLocaleString())}
    </span>,
    " $DePINs\n",
    "   /.. ",
    <span className="text-[#4284FF]">Total Tokens Already Claimed:</span>,
    <span> {userAddressInfo && userAddressInfo.amount.toLocaleString()}</span>,
    // "\n   /.. ",
    // <span className="text-[#4284FF]">Total Holders:</span>,
    // " 8,250",
    "\n\n>_ Estimating Transaction Costs...\n",
    // "   /.. ",
    // <span className="text-[#4284FF]">Network Gas Fee:</span>,
    // " 0.00018 IOTX\n",
    "   /.. Note: Additional ETH/IOTX may be required for transaction fees.\n\n",
    ">_ ",
    <span className="text-[#4284FF]">SYSTEM MESSAGE:</span>,
  ];

  const claimableSystemMessage = [
    ' "Are you ready to claim?"\n',
    "   /.. ===================\n   /.. = ",
    <span
      className="text-[#4284FF] cursor-pointer hover:text-[#22C55E]"
      onClick={() => toggleConfirmModal()}
    >
      [Claim $DePINs]
    </span>,
    " =\n   /.. ===================\n",
  ];

  const notClaimableSystemMessage = ["You do not have any claimable tokens."];

  return (
    <MainComponent>
      {showConfirmModal && (
        <Modal
          toggleModal={toggleConfirmModal}
          className="md:mx-[32px] mx-[16px]"
        >
          <div className="flex flex-col items-center justify-center gap-[32px]">
            <div className="flex flex-col items-center justify-center w-full text-[#4284FF]">
              <Icon
                icon="material-symbols-light:warning-outline"
                className="text-[85px]"
              />
              <p className="text-xl">ATTENTION</p>
            </div>
            <div className="text-center w-full">
              <p>
                Token claims are irreversible. Please verify all information
                before proceeding.
              </p>
            </div>
            <div className="flex white-space-pre-wrap">
              <span className="text-[#4284FF]">Claimable Tokens: </span>
              <span className="text-[#22C55E]">
                {claimData
                  ? userAddressInfo
                    ? (
                        Number(ethers.formatEther(claimData?.amount)) -
                        userAddressInfo.amount
                      ).toLocaleString()
                    : Number(
                        ethers.formatEther(claimData?.amount)
                      ).toLocaleString()
                  : 0}
              </span>
            </div>
            <div className="flex gap-[24px]">
              <Button
                variant="primary"
                colour="black"
                className="border border-[#4284FF]"
                onClick={toggleConfirmModal}
              >
                Cancel
              </Button>
              <Button variant="primary" colour="blue" onClick={handleClaim}>
                Confirm Claim
              </Button>
            </div>
          </div>
        </Modal>
      )}

      {showAnimation && (
        <Reveal className="flex absolute w-full h-screen items-center justify-center z-20 top-0">
          <video
            muted
            autoPlay
            className="w-full h-full object-cover top-0"
            onEnded={() =>
              navigate(RESULT, {
                state: {
                  amount: (
                    Number(ethers.formatEther(claimData?.amount)) - iotexBalance
                  ).toLocaleString(),
                  inscription_count: claimData.proof.length.toLocaleString(),
                  transaction_hash: claimDataResponse.hash,
                },
              })
            }
          >
            <source src="/animations/claim_animation.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </Reveal>
      )}
      <Reveal className="flex relative w-full h-screen items-center justify-center pt-24">
        <video
          muted
          autoPlay
          loop
          className="absolute w-full h-full object-cover top-0"
        >
          <source src="/animations/depins_grid_3.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute w-full h-screen bg-black bg-opacity-80 backdrop-blur-sm top-0" />
        <div className="border-2 border-[#4284FF] w-[704px] p-[32px] bg-black drop-shadow-[0_0_12px_rgba(0,88,255,32)]">
          <p className="whitespace-pre-wrap">
            {claimableText.map((part, index) => (
              <React.Fragment key={index}>{part}</React.Fragment>
            ))}
            {claimData &&
            Number(ethers.formatEther(claimData?.amount)) -
              userAddressInfo?.amount !=
              0
              ? claimableSystemMessage.map((part, index) => (
                  <React.Fragment key={index}>{part}</React.Fragment>
                ))
              : notClaimableSystemMessage.map((part, index) => (
                  <React.Fragment key={index}>{part}</React.Fragment>
                ))}
          </p>
        </div>
      </Reveal>
    </MainComponent>
  );
};

export default ClaimPage;
