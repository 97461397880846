import React from "react";
import { Navigate, useRoutes } from "react-router-dom";
import { HOME, CLAIM, COMING_SOON, LIVE, RESULT } from "./path";
import Home from "../Page/Home";
import ClaimPage from "../Page/ClaimPage";
import ComingSoon from "../Page/ComingSoon";
import Live from "../Page/Live";
import ResultPage from "../Page/ResultPage";

export default function Router() {
  const checkAddressExist = () => !!sessionStorage.getItem("wallet_address");
  const routes = [
    {
      path: HOME,
      element: <Home />,
    },
    {
      path: CLAIM,
      element: checkAddressExist() ? <ClaimPage /> : <Navigate to={HOME} />,
    },
    {
      path: LIVE,
      element: <Live />,
    },
    {
      path: RESULT,
      element: checkAddressExist() ? <ResultPage /> : <Navigate to={RESULT} />,
    },
    {
      path: COMING_SOON,
      element: <ComingSoon />,
    },
  ];

  return useRoutes(routes);
}
