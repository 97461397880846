import React from "react";
import MainComponent from "../Component/MainComponent";
import Reveal from "../Component/Animation/Reveal";
import { useLocation } from "react-router-dom";
import Button from "../Component/Button";

const ResultPage = () => {
  const { state } = useLocation();

  return (
    <MainComponent>
      <Reveal className="flex relative w-full h-screen items-center justify-center">
        <video
          muted
          autoPlay
          loop
          className="absolute w-full h-full object-cover top-0"
        >
          <source src="/animations/depins_grid_3.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
        <div className="absolute w-full h-screen bg-black bg-opacity-80 backdrop-blur-sm top-0" />
        <div className="flex flex-col items-center justify-center z-10 gap-[64px] px-[16px] lg:p-0">
          <div className="flex flex-col items-center justify-center">
            <h1
              className="md:text-[49px] text-[24px] text-[#4284FF] drop-shadow-[0_0_24px_rgba(66,132,255,1)]"
              style={{ fontFamily: "Array-Regular" }}
            >
              {state?.amount ?? 0} $DePINs Claimed
            </h1>
            <p className="lg:text-lg text-md text-center">
              You have claimed your {state?.amount ?? 0} $DePINs from your{" "}
              {state?.inscription_count ?? 0} inscriptions!
            </p>
          </div>
          <div className="border-2 border-[#4284FF] md:w-[300px] md:h-[300px] w-[254px] h-[254px] bg-black drop-shadow-[0_0_12px_rgba(0,88,255,32)]">
            <video
              muted
              autoPlay
              loop
              className="w-full h-full object-cover top-0"
            >
              <source src="/animations/coin.webm" type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </div>
          <Button
            variant="primary"
            colour="blue"
            onClick={() =>
              window.open(
                "https://iotexscan.io/tx/" + state?.transaction_hash ?? "",
                "_blank"
              )
            }
          >
            Your Transaction Hash
          </Button>
        </div>
      </Reveal>
    </MainComponent>
  );
};

export default ResultPage;
