import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router/route";

function App() {
  return (
    <>
      <div className="flex flex-col w-full h-screen bg-black text-white overflow-y-auto no-scrollbar">
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
