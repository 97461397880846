import { useCallback } from "react";

const useShorten = () => {
  const shorten = useCallback((address, length = 6) => {
    if (!address) return "";
    return `${address.substring(0, length)}...${address.substring(
      address.length - 4
    )}`;
  }, []);

  return shorten;
};

export default useShorten;
